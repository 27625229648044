<template>
  <!--begin::Navbar-->
  <div>
    <div v-if="!isLoaded" class="mx-auto my-auto text-center">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="isLoaded && isError"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <div class="card shadow mb-7">
        <div class="card-body pt-6 pb-0">
          <!--begin::Details-->
          <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
            <!--begin::Info-->
            <div class="flex-grow-1">
              <!--begin::Title-->
              <div
                class="d-flex justify-content-between align-items-start flex-wrap mb-2"
              >
                <!--begin::User-->
                <div class="d-flex flex-column">
                  <!--begin::Name-->
                  <div class="d-flex align-items-center mb-2">
                    <router-link
                      :to="`/enti-aggregati/dettaglio-ente/anagrafica-ente/${id}`"
                      class="text-gray-800 text-hover-primary fs-3 fw-bolder me-1"
                    >
                      {{ ente.denominazione }}
                    </router-link>
                    <!-- <div class="badge badge-light-success ms-4">
                      Approvata (statico)
                    </div> -->
                  </div>
                  <!--end::Name-->

                  <!--begin::Info-->
                  <div class="d-flex flex-wrap fw-bold fs-6 pe-2">
                    <span class="d-flex align-items-center text-gray-400 me-5">
                      <span class="svg-icon svg-icon-4 me-1">
                        <i class="bi bi-upc" />
                      </span>
                      {{ ente.cod_affiliazione }}
                    </span>
                    <span class="d-flex align-items-center text-gray-400 me-5">
                      <span class="svg-icon svg-icon-4 me-1">
                        <i class="bi bi-geo-alt" />
                      </span>
                      {{ ente.indirizzo }} - {{ ente.comune }} ({{
                        ente.provincia
                      }})
                    </span>
                    <span class="d-flex align-items-center text-gray-400">
                      <span class="svg-icon svg-icon-4 me-1">
                        <i class="bi bi-globe2" />
                      </span>

                      {{ ente.comitato }}
                      <!-- {{ente.}} -->
                    </span>
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::User-->
              </div>
              <!--end::Title-->
            </div>
            <div class="col-sm-4 text-end">
              <div v-if="isEnabled('fnEnteListaEnte') || readOnly">
                <router-link
                  :to="`/ent-aggregati`"
                  class="btn text-hover-primary text-gray-600 fs-6 text-end p-0 m-0"
                >
                  <i class="bi bi-arrow-left fs-6"></i>
                  Lista società
                </router-link>
              </div>
            </div>
            <!--end::Info-->
          </div>
          <!--end::Details-->

          <!--begin::Navs-->
          <div class="d-flex overflow-auto h-55px">
            <ul
              class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
            >
              <li class="nav-item">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/anagrafica-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Anagrafica
                </router-link>
              </li>
              <li class="nav-item" v-if="!ente.affiliazione_secondaria">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/atto-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Atto
                </router-link>
              </li>
              <li class="nav-item" v-if="!ente.affiliazione_secondaria">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/referente-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Referente
                </router-link>
              </li>
              <li class="nav-item" v-if="!ente.affiliazione_secondaria">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/consiglio-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Consiglio
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/impianti-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Impianti
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/documenti-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Documenti
                </router-link>
              </li>
              <li class="nav-item" v-if="!ente.affiliazione_secondaria">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/affiliazione-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Aggregazione
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/ente-tesserati/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Tesserati
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/privacy-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Privacy
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/sanzioni-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Sanzioni
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="`/enti-aggregati/dettaglio-ente/economato-ente/${id}`"
                  class="nav-link text-active-primary me-1 fs-6"
                  active-class="active"
                >
                  Economato
                </router-link>
              </li>
            </ul>
          </div>
          <!--begin::Navs-->
        </div>
      </div>
      <div
        class="mb-7 bg-secondary px-8 py-6 rounded"
        v-if="ente.affiliazione_primaria && !ente.affiliazione_secondaria"
      >
        <div class="blueFit fs-8">
          Il legale rappresentante dell’Associazione/Società dichiara di essere
          consapevole delle responsabilità penali e degli effetti amministrativi
          derivanti dalla falsità in atti e dalle dichiarazioni mendaci (così
          come previsto dagli artt. 75 e 76 del D.P.R. n. 445 del 28.12.2000),
          ai sensi e per gli effetti di cui agli artt. 46 e 47 del medesimo
          D.P.R. n. 445 del 28.12.2000.
        </div>
      </div>
      <div
        class="mb-7 bg-light-danger px-8 py-6 rounded"
        v-if="ente.affiliazione_primaria && ente.affiliazione_secondaria"
      >
        <div class="text-danger fs-6 fw-bold">
          Attenzione! Si sta operando sull'affiliazione secondaria -
          {{ ente.comitato }}
        </div>
      </div>
      <!--end::Navbar-->
      <router-view
        v-if="ente.id"
        @enteView="enteView"
        :ente="ente"
      ></router-view>
    </div>
    <!-- <div v-else class="mx-auto my-auto text-center">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div> -->
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from "vue";

import services from "../../../axios/dbManag";
import { useRoute, useRouter } from "vue-router";
import isEnabled from "@/composables/isEnabled.js";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";

export default defineComponent({
  name: "dettaglio-ente",
  props: ["comitato"],
  components: {},
  setup() {
    const store = useStore();
    //routing
    const router = useRouter(); //usato per redirect
    const route = useRoute(); // prende id
    //Creazione ente
    const ente = ref({}); // RIVEDERE CON JASON
    const campiLocked = ref("");
    const id = ref(route.params.id);
    const isLoaded = ref(false);
    const isError = ref(false);

    const enteView = () => {
      isLoaded.value = false;
      services.dbManag
        .post(`/anagrafiche/enti-aggregati/titolo/sezione/view`, {
          id: id.value,
        })
        .then((res) => {
          ente.value = { ...res.data.results[0] };
          campiLocked.value = res.data.campi_Locked;
          store.commit(
            "set_puo_utilizzare_pulsanti_sezioni",
            ente.value.puo_utilizzare_pulsanti_sezioni
          );
        })
        .then(() => {
          isLoaded.value = true;
        })
        .catch(() => {
          isError.value = true;
          Swal.fire({
            text: "Attenzione! Si è verificato un errore. Riprovare più tardi",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Riprova",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            router.push({ name: "enti-aggregati" });
          });
        });
    };
    enteView();

    watch(
      () => route.params.id,
      async () => {
        if (route.path.includes("anagrafica-ente")) {
          id.value = route.params.id;
          await enteView();
        } else return;
      }
    );

    return {
      ente,
      id,
      campiLocked,
      isLoaded,
      isError,
      enteView,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
});
</script>
